import { render, staticRenderFns } from "./EditProfileDetailFamily.vue?vue&type=template&id=60d00301&scoped=true&"
import script from "./EditProfileDetailFamily.vue?vue&type=script&lang=js&"
export * from "./EditProfileDetailFamily.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d00301",
  null
  
)

export default component.exports